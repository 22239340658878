import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import { find } from 'lodash'

const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}

const addDaysToCurrentDate = ( daysToAdd = 1 ) => {
    let date = new Date()
    date.setUTCDate(date.getUTCDate() + daysToAdd ) // Usar UTC para evitar desfases por zona horaria

    let day = ("0" + date.getUTCDate()).slice(-2)
    let month = ("0" + (date.getUTCMonth() + 1)).slice(-2)
    let year = date.getUTCFullYear()

    return `${year}-${month}-${day}`
}


const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}

const formatDateOnly = (fecha, locale) => {

    const date = new Date(fecha)
    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = ("0" + (date.getMonth() + 1)).slice(-2)
    const yyy = date.getFullYear()

    const stringEs = yyy + '-' + mes + '-' + dia
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}



const formatBeoProductToSave = (detalles, id, infobeo) => {
    detalles.forEach((detalle) => {
        delete detalle.infobeo
        detalle.ordersdetail = id
        detalle.infobeo = infobeo
        detalle.departamento = detalle.departamentoid,
        delete detalle.departamentoid
        delete detalle.departamentoname
    })
    return detalles
}
const transfersIsValid = (detailOrder) => {
    let valiTranfers = detailOrder.filter(item => item.coupon != '')
    let resultados = valiTranfers.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '')
    
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const transfersIsValidNull = (detailOrder) => {
    let transfersIsValidNull = detailOrder
    let resultados = transfersIsValidNull.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '' || item.itinerary.flighttime == '' || item.itinerary.ito == '' || item.itinerary.ifrom == '')    
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const stringAleatorio = () => {    
    return uuidv4() 
}

const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        
        return true
    } else {
        return false
    }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []    
    const { categoryName, itinerario, operationdate } = product
    if (categoryName == "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Origen" })
        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Destino" })
        }
        if (!itinerario.pax || itinerario.pax == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Pax" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Tipo de servicio" })
        }
    }

   

    if (categoryName != "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
    }
   
    return camposFaltantes
}
const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}




const consoleMsgFinally = (action, message) => {
    if (process.env.NODE_ENV == 'development' ) {
        console.log(`[${action}] ${message}`)
    }
}


const makeArrayCategories = ( categories ) => {

    let hotelServices = []
    let onlyCategories = []
    let withSubCategories = []    
    let categoryServices = [
        { section: 'Services', action: 'get-events', text: 'Eventos', value: 'e634becc-7d3e-11ec-90d6-0242ac120003' },
        { section: 'Services', action: 'get-packages', text: 'Paquetes', value: 'f20a5f0e-7d3e-11ec-90d6-0242ac120003' },
    ]

    categories.forEach((category) => {
        const { section, id, name } = category
        category.action = 'get-products-by-category'
        category.text = name
        category.value = id
        delete category.id
        delete category.name
        if (section == 'Servicios Hoteleros') {
            hotelServices.push(category)
        }
        
        if(category.subcategory.length){    
            category.action = 'get-products-by-sub-category'
            category.uuid = stringAleatorio()
            // category.subcategory = category.subcategory.filter( subC => subC.count > 0)
            withSubCategories.push( category )
            withSubCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente      
        } 

        if(!category.subcategory.length && section == 'Categorías'){
            category.action = 'get-products-by-category'            
            onlyCategories.push( category )
            onlyCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente                                   
            delete category.subcategory 
        }
    })
    // console.log(this.i18n.locale )
    const bestSeller = { section: 'Categorías', action: 'get-products-best-seller', text: 'Best Seller', value: 0 }
    onlyCategories.unshift(bestSeller)
    return { hotelServices, onlyCategories, withSubCategories, categoryServices }
}



const formatItems = ( products, type ) => {
    if( type === 'products'){        
        return formatListProducts( products ) 
    }
    if( type === 'rates'){        
        return formatListProducts( products ) 
    }
    if( type === 'events'){        
        return formatListEvents( products ) 
    } 
    if( type === 'packages'){        
        return formatListPackage( products ) 
    }        
}


const formatListProducts = ( products, valueCategory ) => {
        
    const queryParams= store.state.auth.queryParams

    let items = []
    products.forEach((product ) => {        
        product.modelType = 'product'
        product.valueFromCategory = valueCategory
        product.categoryName = product.category.name || ''        
        // product.total = '0.00'
        // let sum = 0
        product.id = product.experienceId || product.id        
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.type
        delete product.category
        delete product.productinfo
        delete product.detailId 
        delete product.productavailability 
        delete product.rulesProduct 
        delete product.supplierPromo 
        delete product.usabeo 
        // legacy manera detalles con details
        if( product.detail && product.detail.length > 0 ) {

            product.isLegacy = true

            const adult = product.detail.find( d => d.detailDesc.toLowerCase()  === 'adult' || d.detailDesc.toLowerCase() === 'adulto' )
            const child = product.detail.find( d => d.detailDesc.toLowerCase() === 'child' || d.detailDesc.toLowerCase() === 'menor' )
    
            if(adult){
                adult.typeDisplay = 'adult'
                product.detail.push(adult)
            }
            if(child){
                child.typeDisplay = 'child'
                product.detail.push(child)
            }    
            
            if( adult ){
                items.push(product) 
            } 
                                           
        }
        
        const typeRate = { 1:'adult', 2: 'child', 3: 'group' }

        if( product.rates && product.rates.length > 0 ){

            product.isLegacy = false
            product.name = product.displayName
            product.coverImage = product.coverImageUrl
            product.modelType = 'rates'

            product.rates.forEach( rate => {

                rate.typeDisplay = typeRate[rate.type]

                rate.detaildisplay = product.name 
                rate.id = product.experienceContractRateId
                rate.saleprice = rate.amount,
              
                rate.allowExtraPax = false
                rate.descmax = product.maxDiscount || 0
                rate.isBenefit = product.isBenefit || false

                rate.servicesihot = product.servicesihot || rate.servicesihot || null

                if( rate.type === 1 ){
                    rate.qty = queryParams.adults || 0
                }

                if( rate.type === 2 ){
                    rate.qty = queryParams.children || 0
                }

                if(rate.type === 3 ){
                    rate.qty = rate.num
                }                
            })

            product.detail = product.rates

            items.push(product) 

        }
       
        delete product.rates 

    })

    return items
}


const formatListPackage = ( packages, valorCategory ) => {
    packages.forEach((product) => {
        product.modelType = 'package' 
        product.valueFromCategory = valorCategory
        product.detailSelected = {
            descmax: 0,
            detaildisplay: product.name,
            extrapax: false,
            id: product.id,
            saleprice: product.saleprice,
            servicesihot: product.servicesihot
        }
        delete product.products
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.category
        delete product.productinfo
    })

    return packages
}

const formatListEvents = ( events, valueCategory ) => {    
    let items = []
    events.forEach((product ) => {        
        const timeInitFormat = product.startTime.slice(0, -3)
        const timeEndFormat = product.endTime.slice(0, -3)
        const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        product.modelType = 'event'
        product.categoryName = 'Events'  
        product.valueFromCategory = valueCategory
        product.isSelected = false
        product.uuid = `event-${product.id}`                               
        const detalleIngles = product.eventdetail[1]
        product.detailSelected = {
            descmax: detalleIngles ? parseInt(detalleIngles.descmax) : 0,
            detaildisplay: product.name,
            id: detalleIngles ? detalleIngles.id : product.id,
            saleprice: product.saleprice,
            
        }
        product.startDateFormat = formatDate(product.startDate, 'en')
        product.endDateFormat = formatDate(product.endDate, 'en')
        product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        product.currencyCode = product.currency.code
        product.hotelName = product.hotel.name
        product.locationName = product.location.name
        product.description = detalleIngles ? detalleIngles.description : ''
        delete product.eventdetail
        delete product.gallery
        delete product.currency
        delete product.hotel
        delete product.location
        delete product.category
        delete product.productinfo
        if(product.detailSelected  ){
            items.push(product) 
        } 
    })

    return items
}


const formatItemProduct = (item, type) => {    
    if ( type == 'packages') {
        return formatPackage(item)            
    }
    if ( type == 'products') {
        return formatProduct(item)            
    }
    if ( type == 'rates') {
        return formatRatesProduct(item)            
    }

    if ( type == 'events') {
        return formatEvent(item)
    }
}
const formatPackage = (item) => {
    item.categoryName = 'Packages'
    item.modelType = 'package'    
    item.detaildisplay = item.name
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    item.detailSelected = {
        cost: item.cost || 0,
        descmax: 0,
        detailDesc: item.name,
        detaildisplay: item.name,
        
        id: item.id,
        saleprice: item.saleprice, 

    }
    return item
}

const formatProduct = (item) => {
    item.categoryName = 'Products'
    item.modelType = 'product'
    item.total = '0.00'
    
    if (item.gallery && item.gallery.length > 0) {
        const types = ['small', 'medium', 'large']

        item.gallery.forEach((item) => {
            const type = Math.floor(Math.random() * (3 - 0) + 0 )//obtengo valor del 0 a 2            
            item.key = stringAleatorio()
            item.src = process.env.VUE_APP_BASE_URL_SERVER + item.image
            item.style = types[type]            
            delete item.image            
        })
        item.gallery.unshift({
            src: process.env.VUE_APP_BASE_URL_SERVER + item.coverImage,            
            style: 'large'
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    if( item.detail.length ){
        item.detail.forEach( detail => {
            detail.qty = 0
            detail.subTotal = 0
            delete detail.seccionventaid 
        })        
    }
    if(item.productinfo && item.productinfo.length > 0){
        item.termsConditions = item.productinfo[0].terms
    } else {
        item.termsConditions = null
    }

    // delete item.gallery
    delete item.videolink
    delete item.weblink
    delete item.type
    delete item.category
    delete item.productinfo
    delete item.detailId 
    delete item.productavailability 
    delete item.rulesProduct 
    delete item.supplier 
    delete item.supplierPromo 
    delete item.usabeo 

    return item
}

const formatRatesProduct = (item) => {
    item.categoryName = 'Products'
    item.modelType = 'product'
    item.total = '0.00'
    item.name=item.displayName
    item.coverImage=item.coverImageUrl
    if (item.gallery && item.gallery.length > 0) {
        const types = ['small', 'medium', 'large']

        item.gallery.forEach((item) => {
            const type = Math.floor(Math.random() * (3 - 0) + 0 )//obtengo valor del 0 a 2            
            item.key = stringAleatorio()
            item.src = process.env.VUE_APP_BASE_URL_SERVER + item.image
            item.style = types[type]            
            delete item.image            
        })
        item.gallery.unshift({
            src: process.env.VUE_APP_BASE_URL_SERVER + item.coverImage,            
            style: 'large'
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    if( item.rates.length ){
        item.rates.forEach( detail => {
            detail.qty = 0
            detail.subTotal = 0
            
        })        
    }
    if(item.productinfo && item.productinfo.length > 0){
        item.termsConditions = item.productinfo[0].terms
    } else {
        item.termsConditions = null
    }

    if( item.rates ){
           
           
        item.modelType = 'rates'
        item.rates.forEach( (rate,index) => {
            switch (rate.type) {
                case 1:
                    rate.type ='adult'
                    break;
                case 2: // Value of foo matches this criteria; execution starts from here
                    rate.type ='child'
                }
            rate.detaildisplay= item.name 
            rate.detailDesc=rate.type
            rate.id=item.experienceContractRateExplodeId
            rate.saleprice= rate.amount,
            rate.qty=0
            rate.subTotal=0
            rate.allowExtraPax= false
            rate.descmax= 0

        })
        item.detail =item.rates
    }
    
    return item
}


const formatEvent = event => {
    delete event.currency
    event.categoryName = 'Events'
    event.modelType = 'event'
    event.hotelName = event.hotel.name
    event.locationName = event.hotel.name
    event.description = event.name
    event.detailDesc = event.name
    event.detaildisplay = event.name
    const timeInitFormat = event.startTime.slice(0, -3)
    const timeEndFormat = event.endTime.slice(0, -3)
    const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    event.startDateFormat = formatDate(event.startDate, 'en')
    event.endDateFormat = formatDate(event.endDate, 'en')
    event.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
    event.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
    event.detailSelected = {
        cost: event.cost || 0,
        descmax: 0,
        detailDesc: event.name,
        detaildisplay: event.name,
        id: event.id,
        saleprice: event.saleprice,   
        
    }    
    delete event.endDate
    delete event.endTime
    delete event.startDate
    delete event.startTime
    delete event.location

    return event
}



const removeNullValuesFromAnObject = (object) => {
    Object.keys(object).forEach(key => {
        if ( object[key] === null || object[key] === '') {
          delete object[key];
        }
    })    
    return object
}

export {          
    currentDate,
    addDaysToCurrentDate,
    formatDate,
    formatDateOnly,
    validateDataInCart,    
    formatBeoProductToSave,
    transfersIsValid,
    transfersIsValidNull,
    stringAleatorio,
    camposRequeridosPorLLenar,
    toDecimal,
    toJson,    
    consoleMsgFinally,    
    makeArrayCategories,
    formatListProducts,    
    formatListPackage,
    formatListEvents,
    formatItems,    
    formatItemProduct,    
    formatRatesProduct,          
    removeNullValuesFromAnObject,    
}