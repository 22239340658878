import Vue from 'vue'
import store from '@/store' //para acceder a state
import { formatItemProduct, formatListProducts, formatListPackage, formatListEvents, currentDate } from '@/helpers/helpers'
import {  fetchCurrencies, fetchPaymentMethods } from '@/modules/start/store/actions'


export async function getInitialContent(context, params){

    context.commit('setLoadingProducts', true) //set State loader  
    const content = await Promise.all([        
        fetchCurrencies(), fetchProducts( params ), fetchProductDetail(context, params), fetchPaymentMethods()
    ])                            
    const [ currencies, products, product, paymentMethods ] = content   
    const { creditCard } = paymentMethods  
    context.commit('start/setCurrencies', currencies, { root: true }) // set State
    context.commit('setProducts', products )   // set State
    context.commit('shop/setPaymentMethodClient', creditCard.id, { root: true } )   // set State
    context.commit('setLoadingProducts', false)  // set State loader  
    return { currencies, products, product, paymentMethods }
}

export async function getInitialContentLanding(context){
    const content = await Promise.all([        
        fetchBestSellersHome(), fetchCategoriesHome()
    ])                            
    const [ products, categories ] = content   
 
    return { products, categories }
}

export async function getInitialProducts(context, requestArray) {
    let items = []
    let requests = requestArray.map( payload => getProduct(payload) ) //generate array of promises to make requests
    const products = await Promise.all(requests) // return array of products as a result
    products.forEach(product => {
        if(product){
            items.push(product)
        }
    });
    return items
}


export async function fetchBestSellerProducts(context, data) {  
    
    const { iddest } = data
    const idseccanal = process.env.VUE_APP_SECTION_CHANNEL  
    const paramsPayload = { bs: 1, idseccanal, language: 1, iddest } 
    if(paramsPayload.iddest == 0 ) delete paramsPayload.iddest
    if(!paramsPayload.iddest) delete paramsPayload.iddest

    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: paramsPayload
        })        
        const payload = response && response.data    
        return formatListProducts( payload, 'BestSeller-01' )                             

    } catch (error) {
        console.log( error )
    } finally {    
        console.log('fetchBestSellerProducts end')
    }
}

export async function fetchProductsRate(context,data) {
    const { idSubCategory, iddest, idCategory, children, adults, date, language } = data

    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL 
    const paramsPayload = { idsub: idSubCategory, idseccanal, iddest,idcat: idCategory, dateRange:date,  adults,  children, language } 
    if(paramsPayload.iddest == 0 ) delete paramsPayload.iddest
    if(!paramsPayload.iddest) delete paramsPayload.iddest
    try {
        context.commit('products/setLoadingProducts', true, { root: true })
        const response = await Vue.axios({
            url: `/productsRates/`,
            params: paramsPayload
             
        })
        const payload = response && response.data
        return formatListProducts( payload, 'Rates-01' )
    } catch (error) {
        console.log( error )
    } finally {
        console.log('fetchProductsRate end')
    }
}
//peticiones para obtener lista de productos por categorias
export async function fetchProductsByCategories(context, data) {    
    const { idCategory, iddest} = data

    const idseccanal = process.env.VUE_APP_SECTION_CHANNEL  
    const paramsPayload ={ idcat: idCategory, idseccanal, iddest } 
    if( paramsPayload.iddest == 0 ) delete paramsPayload.iddest
    if(!paramsPayload.iddest) delete paramsPayload.iddest

    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: paramsPayload
        })
        const payload = response && response.data   
           
        return formatListProducts( payload, 'test') 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchProductsByCategories end')

    }
}

//peticiones para obtener lista de productos por categorias
export async function fetchProductsBySubCategory(context, data) {   
    const { idSubCategory, iddest} = data
    
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL 
    const paramsPayload = { idsub: idSubCategory, idseccanal, iddest } 
    if(paramsPayload.iddest == 0 ) delete paramsPayload.iddest
    if(!paramsPayload.iddest) delete paramsPayload.iddest

    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: paramsPayload
        })
        const payload = response && response.data   
           
        return formatListProducts( payload, 'subcat' ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchProductsBySubCategory end')

    }
}

export async function fetchProductDetail(context, data) {

    const { idItem, endPoint, language } = data     
     
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL
    try {
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: { id: idItem, idseccanal, language}
        })
        const payload = response && response.data             
        if(payload.length > 0 ){            
            const item = formatListProducts(payload, 'products') 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products
            // delete item.gallery
            
            return item[0]
        } else {
            return null
        }
    } catch (error) {
        console.log( error )        
    } finally {
        console.log('fetchProductDetail end')
    }
}

export async function fetchProductRateDetail(context, data) {

    const { idItem, dateRange, idsub, language, adults, children } = data     
     
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL
    try {
        const response = await Vue.axios({
            url: `/productsRates/`,
            params: { id: idItem, idseccanal, language,dateRange,adults,children,idsub}
        })
        const payload = response && response.data             
        if(payload.length > 0 ){            
            const item = formatListProducts( payload, 'rates') 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products            
            return item[0]
        } else {
            return null
        }
    } catch (error) {
        console.log( error )        
    } finally {
        console.log('fetchProductRateDetail end')
    }
}


export async function fetchPackages(context) {    
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL      
    try {        
        const response = await Vue.axios({
            url: `/packages/`,
            params: { idseccanal }
        })
        const payload = response && response.data                      
        return formatListPackage( payload, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003') 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchPackages end')

    }
}


export async function fetchEvents(context) {    
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL       
    try {        
        const response = await Vue.axios({
            url: `/events/`,
            params: { idseccanal }
        })
        const payload = response && response.data            

        const todayDate = currentDate()
        const fecha = new Date(todayDate)
        //se filtra los eventos que sean iguales o mayores a la fecha actual
        const result = payload.filter(item => new Date(item.endDate) >= fecha)             
        return formatListEvents( result, 'e634becc-7d3e-11ec-90d6-0242ac120003' ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchEvents end')

    }
}


export async function getProduct(payload) {
    const { idProducto, endPoint } = payload    
    const idseccanal =  process.env.VUE_APP_SECTION_CHANNEL
    const endPointRequest = endPoint === 'productswebsites' ? 'products' : endPoint
    try {
        const response = await Vue.axios({
            url: `/${endPointRequest}/`,
            params: { id: idProducto, idseccanal }
        })
        const payload = response && response.data        
        if(payload.length > 0){            
            const item = formatItemProduct(payload[0], endPointRequest) 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products
            delete item.gallery        
            return item 
        }
    } catch (error) {
        console.log( error )        
    }
}

